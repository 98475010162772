export function formatDate(date: Date): string {
  return `${date.getFullYear()}-${
    date.getMonth() + 1
  }-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
}
export function checkMobileNumber(mobile: any) {
  return mobile && mobile.match(/^(13|15|16|17|18|19)[0-9]{9}$/);
}

export function isEmail(email: any) {
  const reg = /^[\w-\.]+@[\w-]+(\.[a-z\d-]+)*(\.[a-z]{2,24})$/i;
  return reg.test(email);
}

export const passwordReg =
  /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[\]\\^_`{|}~]{10,20}$/;

// 得到url中指定name的值
export function getUrlParam(name: string) {
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); //构造一个含有目标参数的正则表达式对象
  var r = window.location.search.substr(1).match(reg); //匹配目标参数
  if (r) return decodeURI(r[2]);
  return null; //返回参数值
}

export function getItem(key: string) {
  return window.localStorage.getItem(key);
}

export function setItem(key: string, value: string) {
  window.localStorage.setItem(key, value);
}
