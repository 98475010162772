import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import {
  Form,
  FormItem,
  Input,
  InputPassword,
  Button,
  message,
} from "@prism/ui-components";
import "./Login.less";
import axios from "axios";
import { checkMobileNumber } from "./util";
import { IntlProvider } from "@prism/ui-components/dist/i18n";
import { isEmail } from "./util";
import { generate } from "@ant-design/colors";
const product =
  new URLSearchParams(window.location.search).get("product") || "portal";

const tenantId = new URLSearchParams(window.location.search).get("tenant-id");

if (product !== "portal") {
  var themeStylesheet = document.createElement("link");
  themeStylesheet.setAttribute("href", `./product/${product}/theme.css`);
  themeStylesheet.setAttribute("rel", "stylesheet");
  document.head.appendChild(themeStylesheet);
}

ReactDOM.render(
  <IntlProvider>
    <React.StrictMode>
      <Login />
    </React.StrictMode>
  </IntlProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

function Login() {
  const [userName, setUserName] = useState("");
  const [password, setPassWord] = useState("");
  const [cellphone, setCellphone] = useState("");
  const [appId, setAppId] = useState("2047445601");
  const [smsCode, setSmsCode] = useState("");
  const [countNum, setCountNum] = useState("60s");
  const [isCodeLogin, setCodeLogin] = useState(false);
  const [isCountVisible, setVisible] = useState(false);

  const [mobile, setMobile] = useState("");
  const [resetPassword, setResetPassword] = useState("");
  const [resetPasswordSure, setResetPasswordSure] = useState("");
  const [isResetCountVisible, setCountVisible] = useState(false);
  const [showLogin, setShowLogin] = useState(true);
  const [showResetPassword, setShowResetPassword] = useState(false);

  const DEFAULT_LOGO_URL = `./product/${product}/logo.svg`;
  const DEFAULT_POSTER_URL = `./product/${product}/poster.svg`;
  const DEFAULT_BG_URL = `./product/${product}/bg.png`;

  const [logoUrl, setLogoUrl] = useState<string>();
  const [bgUrl, setBgUrl] = useState(DEFAULT_BG_URL);
  const [slogan, setSlogan] = useState("");
  const [welcomeMessage, setWelcomeMessage] = useState("");
  const [showCopyright, setShowCopyright] = useState(false);
  const [posterUrl, setPosterUrl] = useState(DEFAULT_POSTER_URL);

  useEffect(() => {
    document.title = "登录";
    var search = new URLSearchParams(window.location.search);
    if (search.has("error")) {
      message.error(
        {
          "50001": "密码错误",
          "50002": "用户名不存在",
          "50003": "用户名或密码错误",
          "50009": "用户已冻结",
          "50010": "用户正在激活中",
          "50011": "验证码过期",
          "50012": "用户被锁定，请30分钟之后再试",
          "50013": "验证码错误",
          "50014": "图形验证码有误",
          "50015": "输入的手机号未绑定账号",
        }[search.get("error") as string] || "登录失败"
      );
    }
    axios
      .get(
        `settings/public/LOGIN_PAGE${tenantId ? `?tenantId=${tenantId}` : ""}`
      )
      .then((res) => {
        const { logoUrl, slogan, welcomeMessage, showCopyright, brand } =
          res.data;
        if (brand) {
          setSlogan(brand.slogan);
          setWelcomeMessage(brand.welcomeMessage);
          setLogoUrl(brand.icon);
          setPosterUrl(brand.postUrl);
          setBgUrl(brand.bgUrl);
          typeof brand.showCopyright !== "undefined" &&
            setShowCopyright(brand.showCopyright === "true");

          if (brand.theme) {
            const colors = generate(brand.theme);

            var style = document.createElement("style");
            style.type = "text/css";
            style.innerHTML = `
              :root {
                --prism-theme-color-primary-default: ${colors[4]};
                --prism-theme-color-primary-hover: ${colors[3]};
                --prism-theme-color-primary-pressed: ${colors[5]};
                --prism-theme-color-primary-disable: ${colors[1]};
                --prism-theme-color-primary-background: ${colors[0]};
              }
            `;
            document.head.appendChild(style);
          }
          return;
        }

        logoUrl ? setLogoUrl(logoUrl) : setLogoUrl(DEFAULT_LOGO_URL);
        if (slogan) {
          setSlogan(slogan);
        } else {
          setSlogan(
            "Convertlab Marketing Cloud | One Cloud All Marketing Needs."
          );
        }
        if (welcomeMessage) {
          setWelcomeMessage(welcomeMessage);
        } else {
          setWelcomeMessage("欢迎回来");
        }
        typeof showCopyright !== "undefined" &&
          setShowCopyright(showCopyright === "true");
      });
  }, []);

  interface AuthProvider {
    loginUrl: string;
    logo: string;
    displayName: string;
  }

  const [authProvider, setAuthProvider] = useState<AuthProvider>();
  useEffect(() => {
    var search = new URLSearchParams(window.location.search);
    axios
      .get<any>("auth/identityProviders", {
        params: {
          account: search.get("account"),
          "tenant-id": search.get("tenant-id"),
        },
      })
      .then((res) => {
        setAuthProvider(res.data?.pagination?.records?.[0]);
      });
  }, []);

  useEffect(() => {
    var script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "https://turing.captcha.qcloud.com/TCaptcha.js";
    document.getElementsByTagName("body")[0].appendChild(script);
    script.onload = function () {
      axios
        .get("captcha/init")
        .then((res) => {
          setAppId(res.data.appid);
        })
        .catch((err) => {
          message.error("初始化验证码失败!");
        });
    };
  }, []);

  function onCount() {
    const data = {
      phone: cellphone,
    };
    if (data.phone == "" || checkMobileNumber(data.phone) == null) {
      message.error("请填写正确的手机号码");
      return;
    }
    axios
      .post("portal/verify-code", data)
      .then((res) => {
        if (!res.data.error) {
          message.success("验证码发送成功!");
          setVisible(true);
          let num = 60;
          let text = "";
          let timer = setInterval(() => {
            num--;
            text = num + "s";
            setCountNum(text);
            if (num < 1) {
              clearInterval(timer);
              setVisible(false);
              setCountNum("60s");
            }
          }, 1000);
        } else {
          message.error("验证码发送失败!");
        }
      })
      .catch((err) => {
        message.error("验证码发送失败!");
      });
  }

  function onResetCount() {
    if (checkMobileNumber(mobile) != null) {
      const data = {
        phone: mobile,
      };

      axios
        .post("portal/verify-code", data)
        .then((res) => {
          if (!res.data.error) {
            setShowResetPassword(true);

            message.success(`验证码已经发送至 ${mobile}`);
            setCountVisible(true);
            let num = 60;
            let text = "";
            let timer = setInterval(() => {
              num--;
              text = num + "s";
              setCountNum(text);
              if (num < 1) {
                clearInterval(timer);
                setCountVisible(false);
                setCountNum("60s");
              }
            }, 1000);
          } else {
            message.error("验证码发送失败,请稍后再试");
          }
        })
        .catch((err) => {
          message.error("验证码发送失败!");
        });
    }

    if (isEmail(mobile)) {
      const data = {
        email: mobile,
      };
      axios
        .post("portal/email/verify-code", data)
        .then((res) => {
          if (!res.data.error) {
            setShowResetPassword(true);

            message.success(`验证码已经发送至 ${mobile}`);
            setCountVisible(true);
            let num = 60;
            let text = "";
            let timer = setInterval(() => {
              num--;
              text = num + "s";
              setCountNum(text);
              if (num < 1) {
                clearInterval(timer);
                setCountVisible(false);
                setCountNum("60s");
              }
            }, 1000);
          } else {
            message.error("验证码发送失败,请稍后再试");
            // message.error(res.data.error.message);
          }
        })
        .catch((err) => {
          const errorText = err.response.data.error.message;
          // message.error(errorText);
          message.error("验证码发送失败!");
        });
    }
  }

  function resetPasswordConfirm() {
    const data = {
      phone: mobile,
      newPassword: resetPasswordSure,
      smsCode: smsCode,
    };

    axios
      .put("users/reset", data)
      .then((res) => {
        if (!res.data.error) {
          message.success("密码重置成功，请重新登录!");
          setShowLogin(true);
        } else {
          message.error("密码重置失败!");
        }
      })
      .catch((err) => {
        const errorText = err.response.data.error.message;
        message.error(errorText);
      });
  }

  function handleForget() {
    setShowLogin(false);
  }

  function showCaptcha() {
    // @ts-ignore
    var captchaEle = new TencentCaptcha(
      "2047445601",
      function (res: any) {
        if (res.ret === 0) {
          var loginButton: any = document.getElementById("login-form");
          loginButton.setAttribute(
            "action",
            `login?ticket=${res.ticket}&rands=${res.randstr}`
          );
          loginButton.submit();
        }
      },
      {}
    );
    captchaEle.show();
  }

  function showResetCaptcha() {
    // @ts-ignore
    var captchaEle = new TencentCaptcha(
      "2047445601",
      function (res: any) {
        if (res.ret === 0) {
          onResetCount();
        }
      },
      {}
    );
    captchaEle.show();
  }

  function doLogin() {
    if (userName == "" || password == "") {
      message.error("请输入正确的用户名或密码");
      return;
    }
    var search = new URLSearchParams(window.location.search);
    if (search.has("needCaptcha")) {
      return showCaptcha();
    }
    (document.getElementById("login-form") as HTMLFormElement).submit();
  }

  return (
    <div
      className="root-container"
      style={
        bgUrl
          ? { backgroundImage: `url(${bgUrl})`, backgroundSize: "cover" }
          : {}
      }
    >
      <div
        style={
          posterUrl
            ? {
                backgroundImage: `url(${posterUrl})`,
                backgroundSize: "contain",
              }
            : {}
        }
      />
      {slogan && (
        <div className="page-top-word" title={slogan}>
          {slogan}
        </div>
      )}
      <div>
        <div style={{ display: showLogin ? "block" : "none" }} id="login">
          {logoUrl && (
            <div
              className={`logo ${product}`}
              //   style={{
              //     backgroundImage: `url(${logoUrl})`,
              //     backgroundSize: "contain",
              //   }}
            >
              <img
                src={logoUrl}
                alt=""
                style={{ display: "block", width: "100%" }}
              />
            </div>
          )}
          {product === "portal" ? (
            <h2 title={welcomeMessage}>{welcomeMessage}</h2>
          ) : welcomeMessage ? (
            <h2 title={welcomeMessage}>{welcomeMessage}</h2>
          ) : (
            <h2>
              欢迎回到{" "}
              <img src={`./product/${product}/text.svg`} alt={`${product}`} />
            </h2>
          )}
          {/* @prism/ui-components.Form不支持原生表单提交，只能获取DOM后手动提交 */}
          <div className="page-login-method">
            <span
              onClick={() => {
                setCodeLogin(false);
              }}
              className={
                isCodeLogin ? "span-code-login" : "span-code-login hot"
              }
            >
              密码登录
            </span>
            <span
              onClick={() => {
                setCodeLogin(true);
              }}
              className={
                isCodeLogin ? "span-code-login hot" : "span-code-login"
              }
            >
              验证码登录
            </span>
          </div>
          <div style={{ display: isCodeLogin ? "block" : "none" }}>
            <Form id="login-form-code" method="post" action="./sms/login">
              <FormItem label="">
                <Input
                  value={cellphone}
                  onChange={(e) => {
                    setCellphone(e.target.value);
                  }}
                  type="text"
                  name="phone"
                  placeholder="请输入手机号"
                />
              </FormItem>
              <FormItem className="sms-code-wrap" label="">
                <Input
                  type="text"
                  value={smsCode}
                  onChange={(e) => {
                    setSmsCode(e.target.value);
                  }}
                  name="smsCode"
                  placeholder="请输入短信验证码"
                />
                <span
                  onClick={onCount}
                  style={{
                    display: isCountVisible ? "none" : "block",
                    color: "var(--prism-theme-color-primary-default)",
                  }}
                  className="span-getcode"
                >
                  获取验证码
                </span>
                <span
                  style={{ display: isCountVisible ? "block" : "none" }}
                  className="span-count"
                >
                  {countNum}
                </span>
              </FormItem>
              <p
                className="forget-password"
                style={{ color: "var(--prism-theme-color-primary-default)" }}
              >
                <span onClick={handleForget}>忘记密码</span>
              </p>
              <Button
                className="login-btn"
                type="primary"
                block
                onClick={() => {
                  if (cellphone == "" || smsCode == "") {
                    message.error("请输入正确的手机号码或短信验证码");
                    return;
                  }
                  (
                    document.getElementById(
                      "login-form-code"
                    ) as HTMLFormElement
                  ).submit();
                }}
              >
                登录
              </Button>
              {authProvider && (
                <Button
                  className="login-btn login-btn-idp"
                  type="link"
                  block
                  href={authProvider.loginUrl}
                >
                  <img src={authProvider.logo} />
                  使用{authProvider.displayName}登录
                </Button>
              )}
            </Form>
          </div>
          <div style={{ display: isCodeLogin ? "none" : "block" }}>
            <Form id="login-form" method="post" action="./login">
              <FormItem label="">
                <Input
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                  type="text"
                  name="username"
                  placeholder="请输入用户名或邮箱"
                />
              </FormItem>
              <FormItem label="">
                <Input
                  type="password"
                  value={password}
                  onChange={(e) => setPassWord(e.target.value)}
                  onPressEnter={() => doLogin()}
                  name="password"
                  placeholder="请输入密码"
                />
              </FormItem>
              <p
                className="forget-password"
                style={{ color: "var(--prism-theme-color-primary-default)" }}
              >
                <span onClick={handleForget}>忘记密码</span>
              </p>
              <Button
                className="login-btn"
                type="primary"
                block
                onClick={() => doLogin()}
              >
                登录
              </Button>

              {authProvider && (
                <Button
                  className="login-btn login-btn-idp"
                  type="link"
                  block
                  href={authProvider.loginUrl}
                >
                  {authProvider.logo && <img src={authProvider.logo} />}
                  使用{authProvider.displayName}登录
                </Button>
              )}
            </Form>
          </div>
        </div>
        <div
          style={{ display: showLogin ? "none" : "block" }}
          className="reset-password"
        >
          <div style={{ display: showResetPassword ? "none" : "block" }}>
            <p className="reset-p">重置密码</p>
            <p>
              <Input
                value={mobile}
                onChange={(e) => {
                  setMobile(e.target.value);
                }}
                type="text"
                name="mobile"
                placeholder="请输入手机号或者邮箱"
              />
            </p>
            <p>
              <Button
                className="next-btn"
                type="primary"
                block
                onClick={() => {
                  if (
                    mobile &&
                    (isEmail(mobile) || checkMobileNumber(mobile) != null)
                  ) {
                    showResetCaptcha();
                  } else {
                    message.error("请输入正确的手机号码或者邮箱");
                    return;
                  }
                }}
              >
                下一步
              </Button>
            </p>
          </div>
          <div style={{ display: showResetPassword ? "block" : "none" }}>
            <p className="reset-p">重置密码</p>
            <p className="verycode-to-p">
              验证码已发至<span>{mobile}</span>
            </p>
            <p className="verycode-input-p">
              <Input
                type="text"
                value={smsCode}
                onChange={(e) => {
                  setSmsCode(e.target.value);
                }}
                name="resetcode"
                placeholder="请输入验证码"
              />
              <span
                onClick={onResetCount}
                style={{ display: isResetCountVisible ? "none" : "block" }}
                className="span-getcode"
              >
                获取验证码
              </span>
              <span
                style={{ display: isResetCountVisible ? "block" : "none" }}
                className="span-count"
              >
                {countNum}
              </span>
            </p>

            <p className="inputpassword">
              <InputPassword
                type="password"
                value={resetPassword}
                onChange={(e) => {
                  setResetPassword(e.target.value);
                }}
                name="resetpassword"
                placeholder="请输入新密码(至少10位含大小写数字和字母)"
              />
            </p>

            <p className="inputpasswordsure">
              <InputPassword
                type="password"
                value={resetPasswordSure}
                onChange={(e) => {
                  setResetPasswordSure(e.target.value);
                }}
                name="resetpasswordsure"
                placeholder="请再次输入新密码"
              />
            </p>

            <p>
              <Button
                className="reset-sure-btn"
                type="primary"
                block
                onClick={() => {
                  if (smsCode == "") {
                    message.error("请输入正确的验证码");
                    return;
                  }
                  if (resetPassword == "") {
                    message.error("请输入正确的新密码");
                    return;
                  }
                  if (resetPassword !== resetPasswordSure) {
                    message.error("两次输入的密码不一致");
                    return;
                  }
                  resetPasswordConfirm();
                }}
              >
                确认
              </Button>
            </p>
          </div>
        </div>
        {showCopyright && (
          <div {...{ name: "copyright" }}>
            Copyright © 2021 上海欣兆阳信息科技有限公司 |{" "}
            <img src="./convertlab.gray.svg" alt="logo" />
          </div>
        )}
      </div>
    </div>
  );
}
